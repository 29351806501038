<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <e-commerce-checkout-step-cart-products />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>

        <label class="section-label mb-1">Summary</label>
        <!-- <b-input-group class="input-group-merge coupons">
          <b-form-input placeholder="Coupons" />
          <b-input-group-append is-text>
            <span
              id="input-coupons"
              class="input-group-text text-primary cursor-pointer"
            >Apply</span>
          </b-input-group-append>
        </b-input-group>
        <hr> -->
        <div class="price-details">
          <h6 class="price-title">
            Price Details
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                Products with BCA
              </div>
              <div class="detail-amt">
                {{ order.totalCount }}
              </div>
            </li>
            <template v-for="token in order.XToken">
              <li
                :key="token.name"
                class="price-detail"
              >
                <div class="detail-title">
                  Products with {{ token.name }}
                </div>
                <div class="detail-amt">
                  {{ token.totalCount }}
                </div>
              </li>
            </template>
            <li class="price-detail">
              <div class="detail-amt discount-amt text-success">
                You can trade this products on The Cannaverse
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                Total BCA
              </div>
              <div class="detail-amt font-weight-bolder">
                {{ order.total }} BCA
              </div>
            </li>
            <template v-for="token in order.XToken">
              <li
                :key="token.name"
                class="price-detail"
              >
                <div class="detail-title detail-total">
                  Total {{ token.name }}
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{ token.total }} {{ token.name }}
                </div>
              </li>
            </template>
          </ul>
          <b-button
            variant="primary"
            block
            @click="doTransaction"
          >
            Buy Now
          </b-button>
        </div>

      </b-card>
    </div>
    <!-- loading modal -->
    <b-modal
      id="modal-grower-buy-loading"
      centered
      ok-only
      hide-footer
      ok-disabled
      no-close-on-backdrop
      title="Transaction in progress"
    >
      <b-col>
        <b-progress
          animated
          centered
          :value="signTransactionProgressCount"
          variant="success"
          class="'progress-bar-success'"
        />
        <span
          v-show="msgTransaction!=''"
          class="text-warning"
        >{{ msgTransaction }}</span>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BCard, VBModal, BProgress, BCol, // BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'

export default {
  directives: {
    'v-modal': VBModal,
  },
  components: {
    // BSV
    BButton,
    BCard,
    BProgress,
    BCol,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,

    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  data() {
    let cart
    const productsList = {
      total: 0,
      totalCount: 0,
      name: 'BCA',
      XToken: [{
        name: 'xToken',
        total: 0,
        totalCount: 0,
      }],
    }
    const fetchCartProducts = () => {
      cart = localStorage.getItem('cart')
      if (!cart) {
        // Si no hay carrito, crear uno nuevo
        cart = []
      } else {
        // Si hay un carrito existente, convertirlo de JSON a objeto JavaScript
        cart = JSON.parse(cart)
      }
      productsList.total = 0
      productsList.totalCount = 0
      productsList.XToken = []
      cart.forEach(product => {
        if (product.isNativeToken) {
          // Si existe el token en la lista
          if (productsList.XToken.find(token => token.name === product.tokenSymbol)) {
            // recorrer los productos en el carro y recalcular
            productsList.XToken = productsList.XToken.map(token => ({
              ...token,
              total: token.total + parseFloat(product.price) * product.qty,
              totalCount: token.totalCount + product.qty,
            }))
          } else {
            productsList.XToken.push({
              name: product.tokenSymbol,
              total: parseFloat(product.price) * product.qty,
              totalCount: product.qty,
            })
          }
        } else {
          productsList.total += parseFloat(product.price) * product.qty
          productsList.totalCount += product.qty
        }
      })
    }
    fetchCartProducts()

    const updateCart = () => {
      fetchCartProducts()
    }

    // Actualizar la orden cada x tiempo
    setInterval(() => {
      updateCart()
    }, 1000)
    // console.log(cart)
    // console.log(productsList)
    return {
      order: productsList,
      transactions: [],
      cart: [],
      usrData: {},
      blockchainId: {},
      signTransactionProgressCount: 0,
      signTransactionProgressInterval: null,
      msgTransaction: '',
      isZero: false,
    }
  },
  created() {
    this.usrData = this.$sessionData.get()
    this.isZero = this.usrData.isZero === 1
    this.blockchainId = this.$sessionData.getCurrentNetworkData()
  },
  methods: {
    // abrir modal cargando progressbar
    openLoadingModal() {
      this.openModal('modal-grower-buy-loading')
    },
    closeLoadingModal() {
      this.closeModal('modal-grower-buy-loading')
    },
    openModal(id) {
      this.$bvModal.show(id)
    },
    closeModal(id) {
      this.$bvModal.hide(id)
    },
    fetchCartProducts() {
      let thecart = localStorage.getItem('cart')
      if (!thecart) {
        // Si no hay carrito, crear uno nuevo
        thecart = []
      } else {
        // Si hay un carrito existente, convertirlo de JSON a objeto JavaScript
        thecart = JSON.parse(thecart)
      }
      this.cart = thecart
    },
    approveBcaOnStore() {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async (resolve, reject) => {
        // if (!window.confirm('Do you really want get the selected products on the Store?.')) {
        //   reject()
        //   return
        // }
        this.msgTransaction = 'Pending transaction... please wait'
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        // debugger
        let usrBalance = 100 * 10 ** 18 // 100 BCA default
        const ethBalance = await this.$http.get(`/wallet/${this.$sessionData.getUsrWallet()}/bcabalance?nid=${this.$sessionData.getNetwork()}`).catch(() => false)
        if (ethBalance) {
          if (ethBalance.data) usrBalance = ethBalance.data.balance
        }
        const oBalance = usrBalance.split('.')
        let ceros = '000000000000000000'
        // si hay decimales, quitar ceros
        if (oBalance[1]) ceros = ceros.substring(oBalance[1].length, 18)
        const fullBalance = `${usrBalance.replace('.', '')}${ceros}`

        const properties = {
          spender: '0xC2b56755720Da44C6D911d976fcdDC167F1C454F',
          amount: fullBalance,
        }
        // debugger
        this.$http.post(`/wallet/${this.$sessionData.getUsrWallet()}/approvebca?nid=${this.$sessionData.getNetwork()}`, properties)
          .then(async () => {
            // debugger
            this.valueProgress = 45
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Notification',
            //     icon: 'InfoIcon',
            //     text: 'Your BCA balance has been successfully approved.',
            //     variant: 'success',
            //   },
            // },
            // {
            //   position: 'bottom-center',
            //   timeout: 15000,
            // })
            resolve()
          })
          .catch(() => {
            // debugger
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Could not approved the BCA balance on Store, please try again',
                variant: 'warning',
              },
            },
            {
              position: 'bottom-center',
              timeout: 7000,
            })
            reject()
          })
      })
    },
    approveXTokenOnStore(greenHouseId) {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async (resolve, reject) => {
        this.msgTransaction = 'Getting approve... please wait'
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        // debugger

        const properties = {
          wallet: this.$sessionData.getUsrWallet(),
          spender: '0xC2b56755720Da44C6D911d976fcdDC167F1C454F',
          amount: '100000000000000000000000000',
        }
        // debugger
        this.$http.post(`/greenHouse/${greenHouseId}/approvextoken?nid=${this.$sessionData.getNetwork()}`, properties)
          .then(async () => {
            this.valueProgress = 59
            resolve()
          })
          .catch(() => {
            // debugger
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Could not approved the balance on Store, please try again',
                variant: 'warning',
              },
            },
            {
              position: 'bottom-center',
              timeout: 7000,
            })
            reject()
          })
      })
    },
    allowanceBcaOnStore() {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async resolve => {
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        const allowance = await this.$http.get(`/wallet/${this.$sessionData.getUsrWallet()}/bcaallowance?nid=${this.$sessionData.getNetwork()}&spender=0xC2b56755720Da44C6D911d976fcdDC167F1C454F`).catch(() => false)
        resolve(allowance.data)
      })
    },
    allowanceXTokenOnStore(greenHouseId) {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async resolve => {
        this.$http.defaults.headers['x-access-token'] = this.$sessionData.get().token
        const allowance = await this.$http.get(`/greenhouse/${greenHouseId}/xtokenallowance?nid=${this.$sessionData.getNetwork()}&spender=0xC2b56755720Da44C6D911d976fcdDC167F1C454F&wallet=${this.$sessionData.getUsrWallet()}`).catch(() => false)
        resolve(allowance.data)
      })
    },

    async doTransaction() {
      if (!window.confirm('Are you sure you want to purchase the selected products in your shopping cart?')) return
      // debugger
      if (!this.$sessionData.validateKyc(ToastificationContent, this.$toast)) return
      // if (this.usrData.data.kyc) {
      //   if (!this.usrData.data.kyc.wallet) {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: 'Notification',
      //         icon: 'InfoIcon',
      //         text: 'Please fill the KYC form to continue...',
      //         variant: 'danger',
      //       },
      //     },
      //     {
      //       position: 'bottom-center',
      //       timeout: 5000,
      //     })
      //     return
      //   }
      // } else {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Notification',
      //       icon: 'InfoIcon',
      //       text: 'Please fill the KYC form to continue...',
      //       variant: 'danger',
      //     },
      //   },
      //   {
      //     position: 'bottom-center',
      //     timeout: 5000,
      //   })
      //   return
      // }

      this.openLoadingModal()
      // saber si el usuario tiene aprobado BCA en la tienda para poder comprar
      const approve = await this.allowanceBcaOnStore()
      // approve = parseFloat(approve)
      // debugger
      if (approve <= 0 || approve < this.order.total) {
        await this.approveBcaOnStore()
      }
      this.msgTransaction = 'Pending transaction... please wait'
      setTimeout(() => {
        try {
          document.querySelector('.modal-header .close').style.display = 'none'
        } catch (e) {
          /* eslint-disable no-console */
          console.log(e)
        }
      }, 1000)
      this.signTransactionProgressCount += 5
      this.signTransactionProgressInterval = setInterval(() => {
        if (this.signTransactionProgressCount < 90) {
          this.signTransactionProgressCount += 2
        }
      }, 1000)
      this.fetchCartProducts()
      // debugger
      /* eslint-disable no-restricted-syntax */
      const productPromises = this.cart.map(async product => {
        try {
          // debugger
          // approvar por cada tienda los ERC20 de cada tienda para el usuario comprado
          const approveXToken = await this.allowanceXTokenOnStore(product.greenHouseId)
          // approve = parseFloat(approve)
          // debugger
          if (approveXToken === 0) {
            await this.approveXTokenOnStore(product.greenHouseId)
          }

          // debugger
          /* eslint-disable no-await-in-loop */
          // const response = await axios.get(apiUrl)
          if (product.greenHouseId) {
            await this.$http.post(`/greenhouse/${product.greenHouseId}/mint?nid=${this.blockchainId.id}`, {
              // eslint-disable-next-line no-underscore-dangle
              nftId: product._id,
              publicKey: this.usrData.data.kyc.wallet,
              amountQty: product.qty,
              iz: 0,
            })
          }
        } catch (error) {
          // Manejar el error aquí (error)
        }
      })

      await Promise.all(productPromises)

      // this.cart.forEach(async product => {
      /* eslint-disable no-underscore-dangle */
      // debugger
      // this.transactions.push(
      // )
      // })
      // Promise.all(this.transactions)
      //   .then(() => {
      // console.log(receipt)
      localStorage.setItem('cart', '[]')
      this.signTransactionProgressCount = 0
      clearInterval(this.signTransactionProgressInterval)
      this.closeLoadingModal()
      this.$router.push({ name: 'my-genetic', params: { iz: this.isZero } })
      // })
      // .catch(() => {
      //   this.msgTransaction = 'Sorry, the transaction cannot be processed, try again please'
      //   this.signTransactionProgressCount = 0
      //   clearInterval(this.signTransactionProgressInterval)
      //   setTimeout(() => {
      //     this.closeLoadingModal()
      //     this.msgTransaction = 'Pending transaction... please wait'
      //   }, 5000)
      // })
    },
  },
}
</script>
<style lang="scss" scoped>
  .item-view-radio-group ::v-deep {
    .btn {
      display: flex;
      align-items: center;
    }
  }
  .modal-header .close {
    display:none;
  }
</style>
