<template>
  <form-wizard
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- account detail tab -->
    <tab-content
      title="Cart"
      icon="feather icon-shopping-cart"
    >
      <e-commerce-checkout-step-cart @next-step="formWizardNextStep" />
    </tab-content>

    <!-- social link -->
    <!-- <tab-content
      title="Payment"
      icon="feather icon-credit-card"
    >
      <e-commerce-checkout-step-payment
        :payment-details="checkoutDetails.payment"
        @next-step="formWizardNextStep"
      />
    </tab-content> -->
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
// import ECommerceCheckoutStepPayment from './ECommerceCheckoutStepPayment.vue'
// import {
//   BButton,
// } from 'bootstrap-vue'
import ECommerceCheckoutStepCart from './ECommerceCheckoutStepCart.vue'
// import ECommerceCheckoutStepAddress from './ECommerceCheckoutStepAddress.vue'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,
    // BButton,
    // SFC
    ECommerceCheckoutStepCart,
    // ECommerceCheckoutStepAddress,
    // ECommerceCheckoutStepPayment,
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      // refFormWizard.value.nextTab()

      let cart = localStorage.getItem('cart')
      if (!cart) {
        // Si no hay carrito, crear uno nuevo
        cart = []
      } else {
        // Si hay un carrito existente, convertirlo de JSON a objeto JavaScript
        cart = JSON.parse(cart)
      }
      /* eslint-disable no-underscore-dangle */
      this.$http.post(`/greenhouse/${this.$props.greenhouseid}/mint?nid=${this.blockchainId.id}`, {
        nftId: this.geneticSelectedData._id,
        publicKey: this.usrData.data.kyc.wallet,
        password: this.pinWalletTransaction,
        iz: this.iz ? 1 : 0,
      })
        .then(receipt => {
          this.msgTransaction = 'The transaction has been confirmed...'
          this.showTransactionDetailData.hash = receipt.data.transactionHash
          this.showTransactionDetailData.msg = 'The transaction has been confirmed successful'
          this.openNftDetailModal()
          setTimeout(() => {
            this.$router.push({ name: 'my-genetic', params: { iz: this.iz } })
          }, 3000)
        })
        .catch(() => {
          // console.log(e)
          this.msgTransaction = 'Sorry, the transaction cannot be processed, try again please'
          setTimeout(() => {
            this.resetDataTransaction()
          }, 5000)
        })
    }

    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: '',
        mobile: '',
        houseNo: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        addressType: null,
      },
      payment: {
        cvv: '',
      },
    })

    return {
      refFormWizard,
      formWizardNextStep,

      // Dummy Details
      checkoutDetails,
    }
  },
  data() {
    const usrData = this.$sessionData.get()
    return {
      iz: usrData.isZero,
    }
  },
  methods: {
    back() {
      this.$router.push({ name: '/', params: { iz: this.iz } })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
